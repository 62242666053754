import { AnimatePresence, motion } from "framer-motion";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";
import React, { useEffect, useState, useRef } from "react";

import FormModal from "./contactUsModal";
import { SolutionsSection } from "./v2.footer";

export default function LandingNavBar({
  textCol,
}: {
  textCol?: string;
  fromBooks?: boolean;
}) {
  const [showContactModal, setShowContactModal] = useState(false);

  const [showSolutionsModal, setShowSolutionsModal] = useState(false);

  const ref = useRef<HTMLDivElement>(null);

  useOnClickOutside(ref, () => setShowSolutionsModal(false));

  useEffect(() => {
    if (showSolutionsModal) {
      // add style overflow hidden to body
      document.documentElement.style.overflow = "hidden";
      // remove style overflow hidden to body on unmount
      return () => {
        document.documentElement.style.overflow = "unset";
      };
    }
  }, [showSolutionsModal]);

  const router = useRouter();

  return (
    <header
      className={`fixed top-0 z-[130] hidden h-[60px] w-full items-center justify-start gap-16 px-[3.5rem] pl-6 pr-8 pt-0 shadow-lg transition-all duration-300 ease-in md:flex ${
        showSolutionsModal
          ? "bg-[#001F01] text-white"
          : "bg-white text-[#018906]"
      }`}
    >
      <KnackAppLogo showSolutionsModal={showSolutionsModal} textCol={textCol} />
      <nav
        id="nav"
        className={`nav flex w-full items-center justify-between gap-2 xl:gap-4 2xl:gap-6 ${textCol}`}
      >
        <div className="hidden items-center gap-3 md:flex 2xl:gap-4">
          <button
            onClick={() => {
              setShowSolutionsModal(!showSolutionsModal);
            }}
            className={`btn rounded-3xl px-4  py-2 text-[14px]  font-medium transition-all duration-150 ease-in hover:bg-[#018906]/20`}
          >
            Solutions
          </button>
          <button
            onClick={() => {
              setShowSolutionsModal(!showSolutionsModal);
            }}
            className={`btn rounded-3xl px-4  py-2 text-[14px]  font-medium transition-all duration-150 ease-in hover:bg-[#018906]/20`}
          >
            Resources
          </button>
          <Link
            href="/about"
            className={`rounded-3xl px-4  py-2 text-[14px]  font-medium transition-all duration-150 ease-in hover:bg-[#018906]/20 ${
              router.pathname.startsWith("/about") && "bg-[#018906]/20"
            }`}
          >
            About
          </Link>
          <button
            onClick={() => setShowContactModal(true)}
            className={`rounded-3xl px-4  py-2 text-[14px]  font-medium transition-all duration-150 ease-in hover:bg-[#018906]/20`}
          >
            Contact
          </button>
        </div>

        <div className="hidden items-center gap-4 text-[#585858] md:flex">
          <Link
            href="/login/business"
            className={`rounded-md border-[1px]  px-8 py-2 text-[14px] font-semibold transition-all duration-150 ease-in  ${
              showSolutionsModal
                ? "border-white text-white hover:bg-[#001F01]"
                : "border-[#018906] text-[#018906] hover:bg-[#018906]/20"
            }`}
          >
            Login
          </Link>
          <Link
            href="/signup/business"
            className={`whitespace-nowrap rounded-md border-[1px] border-[#018906] bg-[#018906] px-8 py-2 text-[14px] font-semibold text-white transition-all duration-150 ease-in hover:bg-[#016204]`}
          >
            Get free account
          </Link>
        </div>
      </nav>
      <AnimatePresence>
        {showContactModal && (
          <FormModal
            closeModal={() => setShowContactModal(false)}
            showModal={showContactModal}
          />
        )}
      </AnimatePresence>

      <AnimatePresence>
        {showSolutionsModal && (
          <motion.div
            initial={{ height: "0px" }}
            animate={{ height: "490px" }}
            transition={{ duration: 0.5, ease: "easeIn" }}
            exit={{ height: "0px" }}
            ref={ref}
            className="no-scrollbar bg-green fixed inset-0 top-[0rem] w-full overflow-hidden overflow-y-auto"
            onScroll={(e) => {
              e.stopPropagation();
            }}
            onScrollCapture={(e) => {
              e.stopPropagation();
            }}
          >
            <SolutionsSection
              close={() => setShowSolutionsModal(false)}
              fromNav
            />
            <button
              onClick={() => setShowSolutionsModal(false)}
              className="absolute right-4 top-4 flex h-8 w-8 items-center justify-center rounded-full border-[2px] border-white text-white"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="lucide lucide-x"
              >
                <path d="M18 6 6 18" />
                <path d="m6 6 12 12" />
              </svg>
            </button>
          </motion.div>
        )}
      </AnimatePresence>
    </header>
  );
}

export function KnackAppLogo({
  textCol,
  toTop,
  showSolutionsModal,
}: {
  textCol?: string;
  toTop?: boolean;
  showSolutionsModal?: boolean;
}) {
  return (
    <nav className="flex h-full items-center" id="logo">
      <Link
        href={toTop ? "/?top=true" : "/"}
        id="KnackApp"
        className={`flex tracking-wide ${textCol}`}
      >
        <Image
          src={showSolutionsModal ? "/logo-light.svg" : "/logo.svg"}
          height={40}
          width={40}
          alt="KnackApp"
        />
      </Link>
    </nav>
  );
}

export function PWANudge({ closeModal }: { closeModal: () => void }) {
  const ref = useRef<HTMLDivElement>(null);

  useOnClickOutside(ref, () => closeModal());
  return (
    <motion.div
      ref={ref}
      initial={{ scale: 0.8, opacity: 0.7 }}
      animate={{ scale: 1, opacity: 1 }}
      exit={{ y: 10, opacity: 0, transition: { duration: 0.3 } }}
      transition={{ duration: 0.3, ease: "easeIn" }}
      className="fixed right-[3.5rem] top-[6rem] z-[130] flex h-max w-[320px] flex-col gap-4 bg-[#272D41] p-4 text-white"
    >
      <svg
        onClick={() => closeModal()}
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={2}
        stroke="currentColor"
        className="absolute -right-8 -top-8 h-8 w-8 cursor-pointer"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
        />
      </svg>

      <h4 className="text-xl font-bold">Get the best of KnackApp</h4>
      <p>
        Click the arrow inside the URL bar to get KnackApp onto your desktop or
        laptop.
      </p>
      <div className="flex items-center gap-2 text-[#00DF79]">
        <p>Look for this symbol</p>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          enableBackground="new 0 0 24 24"
          height="24px"
          viewBox="0 0 24 24"
          width="24px"
          fill="#00DF79"
        >
          <g>
            <rect fill="none" height="24" width="24" />
          </g>
          <g>
            <g>
              <path d="M20,17H4V5h8V3H4C2.89,3,2,3.89,2,5v12c0,1.1,0.89,2,2,2h4v2h8v-2h4c1.1,0,2-0.9,2-2v-3h-2V17z" />
              <polygon points="17,14 22,9 20.59,7.59 18,10.17 18,3 16,3 16,10.17 13.41,7.59 12,9" />
            </g>
          </g>
        </svg>
      </div>
    </motion.div>
  );
}

export function useOnClickOutside(
  ref: React.RefObject<HTMLDivElement>,
  handler: (event: MouseEvent | TouchEvent) => void
) {
  useEffect(() => {
    const listener = (event: MouseEvent | TouchEvent) => {
      // Do nothing if clicking ref's element or descendent elements
      if (!ref.current || ref.current.contains(event.target as Node)) {
        return;
      }

      handler(event);
    };

    document.addEventListener("mousedown", listener);

    return () => {
      document.removeEventListener("mousedown", listener);
    };
  }, [ref, handler]);
}
