import Image from "next/image";
import React from "react";

import { Karma } from "next/font/google";
import Link from "next/link";

import { AnimatePresence, motion } from "framer-motion";
import TalentDimensions from "components/gamification/dimensions";
import FormModal from "./contactUsModal";
import NewsletterModal from "./newsletterModal";

const font = Karma({
  weight: ["400", "600", "700"],
  subsets: ["latin"],
});

export default function Footer() {
  return (
    <footer className="flex w-full flex-col bg-[#001F01]">
      <SolutionsSection />
      <div className="flex w-full flex-col gap-16 bg-black pt-16 text-center">
        <h2
          className={`-mb-4 text-3xl font-semibold text-white ${font.className}`}
        >
          We don’t stop innovating!
        </h2>
        <div className="flex flex-col items-center justify-center   gap-16 md:flex md:flex-row">
          <OneApp
            title="Visit Ascend"
            link="https://ascend-ai.com"
            image="https://res.cloudinary.com/knackapp/image/upload/v1689253388/static/footer/760fcef38540558ddb0741bec3e28307.png"
          />
          <OneApp
            title="Visit Guru"
            link="https://go-guru.com"
            image="https://res.cloudinary.com/knackapp/image/upload/v1689253390/static/footer/0c3e4cbde2034a943634bf1ebea9ffde.png"
          />
        </div>
        <div className="flex w-full items-center justify-between px-8 pb-4 text-sm font-medium text-white">
          <Link href={"/"} id="KnackApp" className={`flex tracking-wide`}>
            <Image src="/logo.svg" height={40} width={40} alt="KnackApp" />
          </Link>
          <p>Knack, Inc. (c) {new Date().getFullYear()}</p>
        </div>
      </div>
    </footer>
  );
}

export function SolutionsSection({
  fromNav,
  close,
}: {
  fromNav?: boolean;
  close?: () => void;
}) {
  const [showDimensionsModal, setShowDimensionsModal] = React.useState(false);
  const [showContactUs, setShowContactUs] = React.useState(false);
  const [showNewsletterModal, setShowNewsletterModal] = React.useState(false);
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.3, ease: "easeIn" }}
      className="flex flex-col gap-10 overflow-y-auto bg-[#001F01] p-8 pb-16 pt-10"
    >
      {!fromNav && (
        <div className="flex flex-col items-center gap-4 pt-8">
          <h2
            className={`text-2xl font-medium text-[#A4FFA7] ${font.className}`}
          >
            KnackApp won innovation awards and recognition from
          </h2>
          <div className="relative h-[100px] w-full">
            <Image
              src="https://res.cloudinary.com/knackapp/image/upload/v1689751385/static/3df29b6f7dc9440d93a84813341cff50.png"
              fill
              className="object-contain"
              alt="KnackApp won innovation awards and recognition"
            />
          </div>
        </div>
      )}
      {!fromNav && <div className="h-[1px] min-h-[1px] w-full bg-[#A4FFA7]" />}
      <div className="flex flex-col gap-8 md:ml-20 md:grid md:w-full md:grid-cols-2 md:justify-around  md:gap-0 md:gap-y-4  lg:ml-0 lg:flex lg:w-full lg:flex-row lg:gap-y-0">
        <div className="flex flex-col gap-3">
          <h3 className="text-lg font-semibold text-[#A4FFA7]">
            Talent & Potential
          </h3>
          <div className="flex flex-col gap-3">
            <span
              onClick={() => {
                setShowDimensionsModal(true);
              }}
              className="cursor-pointer text-base font-medium text-white transition-all duration-200 ease-in hover:text-[#A4FFA7]"
            >
              People Skills
            </span>
            <span
              onClick={() => {
                setShowDimensionsModal(true);
              }}
              className="cursor-pointer text-base font-medium text-white transition-all duration-200 ease-in hover:text-[#A4FFA7]"
            >
              Innovation Skills
            </span>
            <span
              onClick={() => {
                setShowDimensionsModal(true);
              }}
              className="cursor-pointer text-base font-medium text-white transition-all duration-200 ease-in hover:text-[#A4FFA7]"
            >
              Teamwork Skills
            </span>
            <span
              onClick={() => {
                setShowDimensionsModal(true);
              }}
              className="cursor-pointer text-base font-medium text-white transition-all duration-200 ease-in hover:text-[#A4FFA7]"
            >
              Leadership skills
            </span>
            <span
              onClick={() => {
                setShowDimensionsModal(true);
              }}
              className="cursor-pointer text-base font-medium text-white transition-all duration-200 ease-in hover:text-[#A4FFA7]"
            >
              Cognitive Skills
            </span>
            <span
              onClick={() => {
                setShowDimensionsModal(true);
              }}
              className="cursor-pointer text-base font-medium text-white transition-all duration-200 ease-in hover:text-[#A4FFA7]"
            >
              Learning Skills
            </span>
            <span
              onClick={() => {
                setShowDimensionsModal(true);
              }}
              className="cursor-pointer text-base font-medium text-white transition-all duration-200 ease-in hover:text-[#A4FFA7]"
            >
              Personal traits
            </span>
            <span
              onClick={() => {
                setShowDimensionsModal(true);
              }}
              className="cursor-pointer text-base font-medium text-white transition-all duration-200 ease-in hover:text-[#A4FFA7]"
            >
              Training paths
            </span>
            <span
              onClick={() => {
                setShowDimensionsModal(true);
              }}
              className="cursor-pointer text-base font-medium text-white transition-all duration-200 ease-in hover:text-[#A4FFA7]"
            >
              Education paths
            </span>
            <span
              onClick={() => {
                setShowDimensionsModal(true);
              }}
              className="cursor-pointer text-base font-medium text-white transition-all duration-200 ease-in hover:text-[#A4FFA7]"
            >
              Professional paths
            </span>
          </div>
        </div>
        <div className="flex flex-col gap-3">
          <h3 className="text-lg font-semibold text-[#A4FFA7]">Solutions</h3>
          <div className="flex flex-col gap-3">
            <Link
              href="/companies"
              onClick={() => {
                close && close();
              }}
              className="cursor-pointer text-base font-medium text-white transition-all duration-200 ease-in hover:text-[#A4FFA7]"
            >
              Companies
            </Link>
            <Link
              href="/secondary-education"
              onClick={() => {
                close && close();
              }}
              className="text-base font-medium text-white transition-all duration-200 ease-in hover:text-[#A4FFA7]"
            >
              Secondary Education
            </Link>
            <Link
              href="/higher-education"
              onClick={() => {
                close && close();
              }}
              className="text-base font-medium text-white transition-all duration-200 ease-in hover:text-[#A4FFA7]"
            >
              Higher Education
            </Link>
            <Link
              href="/skill-development"
              onClick={() => {
                close && close();
              }}
              className="text-base font-medium text-white transition-all duration-200 ease-in hover:text-[#A4FFA7]"
            >
              Skill Development
            </Link>
            <Link
              href="/coaches-and-counselors"
              onClick={() => {
                close && close();
              }}
              className="text-base font-medium text-white transition-all duration-200 ease-in hover:text-[#A4FFA7]"
            >
              Coaches & Counselors
            </Link>
            <Link
              href="/social-sector"
              onClick={() => {
                close && close();
              }}
              className="text-base font-medium text-white transition-all duration-200 ease-in hover:text-[#A4FFA7]"
            >
              Social Sector
            </Link>
          </div>
        </div>
        <div className="flex flex-col gap-3">
          <h3 className="text-lg font-semibold text-[#A4FFA7]">Platform</h3>
          <div className="flex flex-col gap-3">
            <Link
              onClick={() => {
                close && close();
              }}
              href="/#games-section"
              className="cursor-pointer text-base font-medium text-white transition-all duration-200 ease-in hover:text-[#A4FFA7]"
            >
              Games
            </Link>
            <Link
              onClick={() => {
                close && close();
              }}
              href="/#analytics-section"
              className="text-base font-medium text-white transition-all duration-200 ease-in hover:text-[#A4FFA7]"
            >
              Analytics
            </Link>

            <Link
              onClick={() => {
                close && close();
              }}
              href="/#latest-features-section"
              className="text-base font-medium text-white transition-all duration-200 ease-in hover:text-[#A4FFA7]"
            >
              Latest Features
            </Link>
            <Link
              onClick={() => {
                close && close();
              }}
              href="/#results-section"
              className="text-base font-medium text-white transition-all duration-200 ease-in hover:text-[#A4FFA7]"
            >
              Results
            </Link>
            <Link
              onClick={() => {
                close && close();
              }}
              href="/#pricing"
              className="text-base font-medium text-white transition-all duration-200 ease-in hover:text-[#A4FFA7]"
            >
              Pricing
            </Link>
            <Link
              onClick={() => {
                close && close();
              }}
              href="/how-it-works"
              className="text-base font-medium text-white transition-all duration-200 ease-in hover:text-[#A4FFA7]"
            >
              Our IP
            </Link>
          </div>
        </div>
        <div className="flex flex-col gap-3">
          <h3 className="text-lg font-semibold text-[#A4FFA7]">Resources</h3>
          <div className="flex flex-col gap-3">
            <span
              onClick={() => setShowContactUs(true)}
              className="cursor-pointer text-base font-medium text-white transition-all duration-200 ease-in hover:text-[#A4FFA7]"
            >
              Contact
            </span>
            <Link
              href="/faq"
              className="text-base font-medium text-white transition-all duration-200 ease-in hover:text-[#A4FFA7]"
            >
              Help & FAQs
            </Link>
            <Link
              href="/privacy-policy"
              className="text-base font-medium text-white transition-all duration-200 ease-in hover:text-[#A4FFA7]"
            >
              Privacy & Security
            </Link>
            <Link
              href="/terms-of-service"
              className="text-base font-medium text-white transition-all duration-200 ease-in hover:text-[#A4FFA7]"
            >
              Terms of Services
            </Link>
          </div>
        </div>
        <div className="flex flex-col gap-3">
          <h3 className="text-lg font-semibold text-[#A4FFA7]">About</h3>
          <div className="flex flex-col gap-3">
            <Link
              href="/about"
              className="cursor-pointer text-base font-medium text-white transition-all duration-200 ease-in hover:text-[#A4FFA7]"
            >
              Startup story
            </Link>
            <Link
              href="/publications"
              className="text-base font-medium text-white transition-all duration-200 ease-in hover:text-[#A4FFA7]"
            >
              Knowledge Center
            </Link>
            <Link
              href="/books"
              className="text-base font-medium text-white transition-all duration-200 ease-in hover:text-[#A4FFA7]"
            >
              Books
            </Link>
            <Link
              href="/gallery"
              className="text-base font-medium text-white transition-all duration-200 ease-in hover:text-[#A4FFA7]"
            >
              Photo Gallery
            </Link>
            <span
              onClick={() => setShowNewsletterModal(true)}
              className="cursor-pointer text-base font-medium text-white transition-all duration-200 ease-in hover:text-[#A4FFA7]"
            >
              Future Talent
            </span>
            <a
              href="https://knackapp.substack.com"
              target="_blank"
              rel="noopener noreferrer"
              className="text-base font-medium text-white transition-all duration-200 ease-in hover:text-[#A4FFA7]"
            >
              Newsletter
            </a>
          </div>
        </div>
      </div>
      <AnimatePresence>
        {showDimensionsModal && (
          <TalentDimensions close={() => setShowDimensionsModal(false)} />
        )}
      </AnimatePresence>
      <AnimatePresence>
        {showContactUs && (
          <FormModal
            closeModal={() => setShowContactUs(false)}
            showModal={showContactUs}
          />
        )}
      </AnimatePresence>
      {showNewsletterModal && (
        <NewsletterModal closeModal={() => setShowNewsletterModal(false)} />
      )}
    </motion.div>
  );
}

function OneApp({
  image,
  title,
  link,
}: {
  image: string;
  title: string;
  link: string;
}) {
  return (
    <div className="flex flex-col items-center gap-2">
      <Image
        src={image}
        alt="Title"
        width={140}
        height={140}
        className="object-contain"
      />
      <h3 className="mb-6 text-lg font-semibold text-white">
        {title?.split(" ")[1]}
      </h3>
      <a
        href={link}
        target="_blank"
        rel="noopener noreferrer"
        className="animateLinkAsButton flex h-[45px] w-[150px] items-center justify-center rounded-md border-[1px] border-white font-medium text-white"
      >
        {title}
      </a>
    </div>
  );
}
